
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      item: { type: Object, default: null },
      visible: { type: Boolean, required: true },
      title: { type: String, default: '' },
    },
    data() {
      return {
        copy: null,
      }
    },
    watch: {
      item: {
        immediate: true,
        handler(value: any) {
          this.copy = { ...value }
        }
      }
    },
    methods: {
      confirm() {
        this.$emit('confirm', this.copy)
      },
      change() {
        this.$emit('update:visible', false)
      },
    }
  })
