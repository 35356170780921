
  import { defineComponent } from 'vue'
  import { orderBy } from 'lodash-es'

  export default defineComponent({
    props: {
      sort: { type: String, default: null },
    },
    data() {
      return {
        loading: true,
        items: [],
      }
    },
    computed: {
      sortedItems(): any[] {
        return this.sort === 'a-z'
          ? orderBy(this.items, 'name')
          : orderBy(this.items, 'albumCount', 'desc')
      },
    },
    async created() {
      this.items = await this.$api.getGenres()
      this.loading = false
    },
  })
