import { render, staticRenderFns } from "./EmptyIndicator.vue?vue&type=template&id=6045f03b&scoped=true&functional=true&"
var script = {}
import style0 from "./EmptyIndicator.vue?vue&type=style&index=0&id=6045f03b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "6045f03b",
  null
  
)

export default component.exports