
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      visible: { type: Boolean, required: true },
    },
    data() {
      return {
        url: '',
        hasError: false,
      }
    },
    methods: {
      confirm(event: any) {
        if (this.url.length === 0) {
          this.hasError = true
          event.preventDefault()
          return
        }
        this.$emit('confirm', this.url)
      },
      change() {
        this.hasError = false
        this.url = ''
        this.$emit('update:visible', false)
      },
    }
  })
