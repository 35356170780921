
  import { defineComponent } from 'vue'
  import { orderBy } from 'lodash-es'
  import AddPodcastModal from '@/library/podcast/AddPodcastModal.vue'
  import { UnsupportedOperationError } from '@/shared/api'

  export default defineComponent({
    components: {
      AddPodcastModal,
    },
    props: {
      sort: { type: String, default: null },
    },
    data() {
      return {
        items: null as null | any[],
        showAddModal: false,
        unsupported: false,
      }
    },
    computed: {
      sortedItems(): any[] {
        return this.sort === 'a-z'
          ? orderBy(this.items, 'name')
          : orderBy(this.items, 'updatedAt', 'desc')
      },
    },
    async created() {
      try {
        this.items = await this.$api.getPodcasts()
      } catch (err) {
        if (err instanceof UnsupportedOperationError) {
          this.items = []
          this.unsupported = true
          return
        }
        throw err
      }
    },
    methods: {
      async refresh() {
        await this.$api.refreshPodcasts()
        this.items = await this.$api.getPodcasts()
      },
      async add(url: string) {
        await this.$api.addPodcast(url)
        this.items = await this.$api.getPodcasts()
        // Backend doesn't always download metadata immediately. Wait and refresh again.
        await new Promise(resolve => setTimeout(resolve, 1000))
        this.items = await this.$api.getPodcasts()
      },
    }
  })
