
  import { defineComponent } from 'vue'
  import AlbumList from '@/library/album/AlbumList.vue'
  import ArtistList from '@/library/artist/ArtistList.vue'
  import TrackList from '@/library/track/TrackList.vue'

  export default defineComponent({
    components: {
      AlbumList,
      ArtistList,
      TrackList,
    },
    props: {
      query: { type: String, required: true }
    },
    data() {
      return {
        result: null as any,
      }
    },
    computed: {
      hasResult(): boolean {
        return this.result && (
          this.result.artists.length > 0 ||
          this.result.albums.length > 0 ||
          this.result.tracks.length > 0)
      }
    },
    watch: {
      query: {
        immediate: true,
        handler(value: string) {
          this.$api.search(value).then(result => {
            this.result = result
          })
        }
      }
    },
  })
