
  import { defineComponent } from 'vue'
  import VueSlider from 'vue-slider-component'
  import { formatDuration } from '@/shared/utils'

  export default defineComponent({
    components: {
      VueSlider,
    },
    computed: {
      progress(): number {
        return this.$store.getters['player/progress']
      },
    },
    methods: {
      formatter(value: number): string {
        const duration = this.$store.state.player.duration
        const time = value * duration
        return `${formatDuration(time)} / ${formatDuration(duration)}`
      },
      seek(value: number) {
        this.$store.dispatch('player/seek', value)
      },
    }
  })
