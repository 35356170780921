
  import { defineComponent } from 'vue'
  import TopNav from '@/app/TopNav.vue'
  import Sidebar from '@/app/Sidebar.vue'
  import Player from '@/player/Player.vue'

  export default defineComponent({
    components: {
      TopNav,
      Sidebar,
      Player,
    },
  })
