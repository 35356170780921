
  import { defineComponent } from 'vue'
  import ArtistList from './ArtistList.vue'
  import { Artist } from '@/shared/api'
  import { orderBy } from 'lodash-es'

  export default defineComponent({
    components: {
      ArtistList,
    },
    props: {
      sort: { type: String, default: null },
    },
    data() {
      return {
        loading: true,
        items: [] as readonly Artist[]
      }
    },
    computed: {
      sortedItems(): Artist[] {
        return this.sort === 'a-z'
          ? orderBy(this.items, 'name')
          : orderBy(this.items, 'albumCount', 'desc')
      },
    },
    async created() {
      this.items = Object.freeze(await this.$api.getArtists())
      this.loading = false
    }
  })
