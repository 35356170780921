
  import { defineComponent } from 'vue'
  import { useFavouriteStore } from '@/library/favourite/store'

  export default defineComponent({
    props: {
      items: { type: Array, required: true },
      allowHScroll: { type: Boolean, default: false },
    },
    setup() {
      return {
        favouriteStore: useFavouriteStore()
      }
    },
    computed: {
      favourites(): any {
        return this.favouriteStore.albums
      },
    },
    methods: {
      async playNow(id: string) {
        const album = await this.$api.getAlbumDetails(id)
        return this.$store.dispatch('player/playTrackList', {
          tracks: album.tracks,
        })
      },
      async playNext(id: string) {
        const album = await this.$api.getAlbumDetails(id)
        return this.$store.dispatch('player/setNextInQueue', album.tracks)
      },
      async playLater(id: string) {
        const album = await this.$api.getAlbumDetails(id)
        return this.$store.dispatch('player/addToQueue', album.tracks)
      },
      toggleFavourite(id: string) {
        return this.favouriteStore.toggle('album', id)
      },
      dragstart(id: string, event: any) {
        event.dataTransfer.setData('application/x-album-id', id)
      },
    }
  })
