
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      text: { type: String, default: '' },
      icon: { type: String, default: null },
      variant: { type: String, default: '' },
    }
  })
