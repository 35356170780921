
  import Vue, { defineComponent } from 'vue'

  export default defineComponent({
    data() {
      return {
        top: 0,
        left: 0,
        visible: false,
      }
    },
    watch: {
      visible: {
        handler(value: boolean) {
          Vue.set((this.$refs.dropdown as any), 'visible', value)
        }
      }
    },
    methods: {
      hide(event: any) {
        event.preventDefault()
        this.visible = false
      },
      showContextMenu(event: any) {
        if (this.$slots['context-menu']) {
          event.preventDefault()
          this.top = event.offsetY
          this.left = event.offsetX
          this.visible = true
        }
      }
    }
  })
